.link-container
{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link
{
    text-decoration: none;
    color:black;
    padding-right: 30px;
    padding-left: 30px; 
    font-family: 'Josefin Slab', serif;
    cursor: pointer;
}

.link-container-left
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.link-container-right
{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.link-container-parent
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}