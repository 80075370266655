.center-item
{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.text-container
{
    display: flex;
    width: 500px;
    flex-wrap: wrap;
    /* font-family: 'Josefin Slab', serif; */
    font-weight: 400;

}

.parent-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: cornflowerblue;
    height: 800px;

}

.amatic
{
    padding: 20px;
    font-family: 'Amatic SC', serif;
    font-size: 60px;
}