.bg 
{
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  /* background-image: url("https://i.ibb.co/BLyqVsj/IMG-0006.jpg"); */
  background-image: url("https://i.ibb.co/VW9WdXC/IMG-0313.jpg");
  background-size: cover;
}

